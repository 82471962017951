@import "breakpoints"; @import "colors";
.CtaButton {
  width: 100% !important;
  min-width: auto !important;
  padding: 6px 24px !important;
  border: 2px solid $fwdOrange !important;

  & :global .MuiButton-label {
    display: flex;
    flex-direction: row !important;
    gap: 10px;

    & :global .MuiButton-startIcon {
      align-self: center;
    }
  }
}

.CtaButton--primary {
  border-color: inherit !important;
}

.CtaButton--icon-right {
  & :global .MuiButton-label {
    flex-direction: row-reverse !important;
  }
}
