@import "breakpoints"; @import "colors";
@import '../variables.scss';
@import '../typography.scss';

@keyframes primaryIn {
  0% {
    color: white;
    background: linear-gradient(to right, white 50%, $fwd-orange 50%);
    background-size: 200% 100%;
    background-position: right bottom;
  }
  100% {
    background: linear-gradient(to right, white 50%, $fwd-orange 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    color: $fwd-orange;
  }
}

@keyframes primaryOut {
  0% {
    background: linear-gradient(to right, $fwd-orange 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: $fwd-orange;
  }
  100% {
    background: linear-gradient(to right, $fwd-orange 50%, white 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    color: white;
  }
}

@keyframes secondaryIn {
  0% {
    background: linear-gradient(to right, $fwd-orange 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: $fwd-orange;
  }
  100% {
    background: linear-gradient(to right, $fwd-orange 50%, white 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    color: white;
  }
}

@keyframes secondaryOut {
  0% {
    background: linear-gradient(to right, white 50%, $fwd-orange 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    color: white;
  }
  100% {
    background: linear-gradient(to right, white 50%, $fwd-orange 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    color: $fwd-orange;
  }
}

.primary,
.primary:focus {
  color: white;
  background-color: $fwd-orange;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  align-items: baseline;
  min-height: 44px; //Fixed by design
  width: 100%;
  min-width: 120px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: none;

  svg {
    fill: #fff;
  }
}

.primary:hover {
  color: $fwd-orange;
  background-color: white;
  box-shadow: $btn-box-shadow;

  svg {
    fill: $fwd-orange;
    path {
      fill: $fwd-orange;
    }
  }
}

.primary,
.secondary.darkMode {
  &.unHovered {
    animation: primaryOut 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }

  &:hover:not(.disabled) {
    animation: primaryIn 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }
}

.secondary,
.primary.darkMode {
  &.unHovered {
    animation: secondaryOut 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }

  &:hover:not(.disabled) {
    animation: secondaryIn 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }
}

.custom,
.custom.darkMode {
  &.unHovered {
    animation: none;
  }

  &:hover:not(.disabled) {
    animation: none;
  }
}

.primary.darkMode {
  color: $fwd-orange;
  border: 1px solid;
  background-color: white;
  &.active {
    color: white;
    background-color: $fwd-orange;
    box-shadow: $btn-box-shadow;
  }

  svg {
    fill: $fwd-orange;
  }
}

.primary.darkMode:hover {
  color: white;
  background-color: $fwd-orange;
  box-shadow: $btn-box-shadow;

  svg {
    fill: #fff;
  }
}

.secondary,
.secondary:focus {
  color: $fwd-orange;
  background-color: white;
  box-sizing: border-box;
  border: 2px solid $fwd-orange;
  padding-top: 10px;
  border-radius: 4px;
  &.active {
    background-color: $fwd-orange;
    color: white;
    box-shadow: $btn-box-shadow;
  }

  svg {
    fill: $fwd-orange;
  }
}

.secondary:hover {
  background-color: $fwd-orange;
  color: white;
  box-shadow: $btn-box-shadow;

  svg {
    fill: white;
  }
}

.secondary.darkMode {
  color: white;
  background-color: $fwd-orange;
  border: 2px solid white;

  svg {
    fill: #fff;
  }
}

.secondary.darkMode:hover {
  color: $fwd-orange;
  background-color: white;

  svg {
    fill: $fwd-orange;
  }
}

.custom,
.custom:focus {
  color: $font-black;
  background-color: white;
  box-sizing: border-box;
  border: 2px solid $fwd-orange;
  padding-top: 10px;
  border-radius: 4px;
  font-weight: 500;

  &.active {
    background-color: $fwd-bg-orange-20;
    color: $font-black;
    box-shadow: $btn-box-shadow;
  }

  svg {
    fill: $fwd-orange;
  }
}

.custom:hover {
  background-color: $fwd-bg-orange-20;
  color: $font-black;
  box-shadow: $btn-box-shadow;

  svg {
    fill: white;
  }
}

.disabled,
.disabled:hover {
  cursor: default;
  box-shadow: none;
}

.primary.disabled:hover {
  background-color: $fwd-orange;
  color: white;

  svg {
    fill: white;
  }
}

.primary.disabled.darkMode:hover {
  color: $fwd-orange;
  background-color: white;
  box-shadow: none;

  svg {
    fill: $fwd-orange;
  }
}

.secondary.disabled:hover {
  background-color: white;
  color: $fwd-orange;

  svg {
    fill: $fwd-orange;
  }
}

.secondary.disabled.darkMode:hover {
  color: white;
  background-color: $fwd-orange;
  box-shadow: none;

  svg {
    fill: white;
  }
}

.innerHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.icon {
  font-size: 1.25rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}

.textWithIcon {
  text-align: left;
}
