.chatbot-avatar-overlay {
  height: 144px;
}

.chatbot-avatar-wrapper .chatbot-avatar {
  height: 44px;
  width: auto;
}

#fwd-chatbot {
  @media only screen and (max-width: 767px) {
    #chatbot-wrapper
      .chatbotv2
      #chatbot-window
      .wc-message-groups.loading-active {
      height: 80%;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper
      .chatbotv2
      #chatbot-window
      .wc-message-groups.loading-active
      .webchat-loading
      .fw-style-spinner {
      margin-top: calc(50vh - 150px);
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbotv2 .wc-message-groups {
      max-height: calc(100% - 160px);
      top: inherit;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbotv2 .wc-chatview-panel {
      height: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbotv2 .chatbot-header {
      border-radius: 0;
      height: 70px;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbotv2 .chatbot-header .chatbot-avator img {
      margin-top: 13px;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbotv2 .chatbot-header .connect-live-agent-button {
      top: 22px;
      right: auto;
      left: 167px;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .chatbotv2 {
      height: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: margin-top 0.3s ease-in-out;
      -o-transition: margin-top 0.3s ease-in-out;
      transition: margin-top 0.3s ease-in-out;
      top: 0;
      right: inherit;
      width: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .chatbot-flowing {
      right: 15px;
      width: 55px;
      height: 55px;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .chatbot-flowing {
      position: fixed;
      top: 21px;
      width: 70px;
      height: 40px;
      z-index: 1150;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: 0 0;
    }
    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize)
      .chatbot-flowing
      .chat-icon
      .icon-chatbot {
      font-size: 22px;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }

    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize)
      .chatbot-flowing
      .chat-icon
      .icon-arrow-down {
      font-size: 21px;
      padding: 4px 18px 0 0;
    }
  }

  #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .chatbotv2 {
    visibility: visible;
    margin-right: 15px;
    z-index: 1100;
  }
  @media only screen and (max-width: 767px) {
    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .chatbotv2 {
      margin-right: 0;
    }
  }
  #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .inputtext {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    #chatbot-wrapper:not(.closeChatbot):not(.chatbotMinimize) .inputtext {
      right: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    #chatbot-wrapper .fwd-language-setting {
      top: 30px;
      font-size: 16px;
    }
  }
}
