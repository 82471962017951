$fwd-orange: #e87722;
$font-black: #183028;
$fwd-grey-dark: #8b8e8f;
$fwd-bg-orange-5: #fef9f4;
$fwd-bg-orange-20: #fae4d3;
$fwd-bg-grey: #dbdfe1;
$fwd-text-dark-green: #183028;
$fwd-line-grey: #dbdfe1;
$btn-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
$fwd-supporting-grey-dark: #b3b6b8;
$fwd-support-grey-darkest: #636566;
$valid-promo-bg-green: #f2f9f6;
$valid-promo-text-green: #03824f;
