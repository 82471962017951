@import "breakpoints"; @import "colors";
@import "./_chatbot.scss";

* {
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #dbdfe1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  // // For FireFox
  scrollbar-width: thin;
  scrollbar-color: #dbdfe1 transparent;
}

// belongs to PricesOfInvestmentChoices component
.itemType-fund {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  > .MuiList-root {
    padding-right: 5px;
    width: calc(100% + 5px);
  }

  .MuiMenuItem-root.Mui-selected .showTickIcon {
    right: 4px !important;
  }
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
  font-weight: 300;

  min-height: 100%;
}

#__next > div {
  min-height: 100%;
}

body {
  min-height: 100vh;
}

html[lang="zh"]
  body
  *:not(.material-icons):not([class^="fib-"]):not([class^="fas"]) {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

a {
  color: #000;
  text-decoration: unset;

  &:hover {
    color: #e87722;
  }
}

b,
strong {
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

.btn-link {
  text-decoration: none;
}

.MuiDialog-root {
  //  &.MuiDialog--mobileHeaderMenu {
  //    z-index: 2147483640 !important;
  //  }

  @media screen and (max-width: 400px) {
    .MuiInputBase-input:not(.MuiSelect-selectMenu) {
      font-size: 1.15rem !important;
    }
  }

  @media screen and (max-width: 375px) {
    .MuiInputBase-input {
      font-size: 1.05rem !important;
    }
  }
}

.fwd-border-bottom:after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #dbdfe1;
}

.mui-dropdown-menu {
  transform: translate(8px, 24px) scale(1);
  max-height: 16rem !important;

  li {
    padding: 8px 32px 8px 16px;

    &:hover {
      color: #ffffff;
      background-color: #e87722;

      .tick-icon {
        svg > path {
          fill: #ffffff;
        }
      }
    }

    &.MuiListItem-root.Mui-selected {
      background-color: #ffffff;
      color: #000;

      &:hover {
        background-color: #e87722;
        color: #ffffff;
      }
    }

    .tick-icon {
      right: 8px;
      width: 100%;
      display: inline-block;
      position: absolute;
      text-align: right;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

[class*="MuiFormGroup-root"] {
  .fieldlabel {
    a {
      color: #fff;
    }
  }

  &.labelGroup {
    &.label-enquiry {
      span {
        display: inline-block;
        word-break: break-all;
      }

      p {
        word-break: break-all;
      }
    }
  }
}

.thankYouPage {
  .fieldlabel {
    span {
      display: inline-block;
      word-break: break-all;
    }

    p {
      word-break: break-all;
    }

    figure {
      img {
        max-width: 100%;
      }
    }
  }
}

.MuiMenu-list {
  .Mui-selected {
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  .MuiMenuItem-root {
    font-weight: 300 !important;
  }
}

@media only screen and (max-width: 960px) {
  .dropdownUl-form-white {
    .MuiList-root {
      &.MuiMenu-list {
        .icon-action-dropDown-white {
          max-width: 20.5rem;
        }
      }
    }
  }
}

body.modal-open {
  overflow: hidden !important;
}

.chatbot-avatar-wrapper {
  .chatbot-avatar-overlay {
    display: none !important;
  }

  &:hover {
    .chatbot-avatar-overlay {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  .chatbotv2 {
    width: 100% !important;
    height: 100% !important;
    right: 0 !important;
    margin: 0 !important;
  }
}

.icon-action-dropDown-white {
  max-width: none !important;
}

.MuiDialog-root {
  .MuiCard-mediaContainer {
    height: 0;
    padding-top: 56.25%;
    position: relative;

    video {
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      object-fit: cover;
    }
  }
}

@keyframes activeDot {
  0%,
  100% {
    transform: scaleY(1);
  }

  25% {
    transform: scaleY(0.75);
  }
}

@keyframes inactiveDot {
  0%,
  100% {
    transform: scaleX(1) scaleY(1);
  }

  25% {
    transform: scaleX(0.85) scaleY(1.25);
  }
}
