@import "breakpoints"; @import "colors";
@import '../variables.scss';
@import '../typography.scss';

.primary,
.primary:focus {
  color: white;
  background-color: $fwd-orange;
  padding: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    fill: white;
  }
}

.primary:hover {
  color: $fwd-orange;
  background-color: white;
  box-shadow: $btn-box-shadow;
  svg path {
    fill: $fwd-orange;
  }
}

.secondary,
.secondary:focus {
  color: $font-black;
  background-color: white;
  box-shadow: $btn-box-shadow;
  svg path {
    fill: $font-black;
  }
}

.secondary:hover {
  color: white;
  background-color: $fwd-orange;
  svg path {
    fill: white;
  }
}

.icon {
  font-size: 1rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.xs {
  width: 24px;
  height: 24px;
  .icon {
    font-size: 1rem;
  }
}

.sm {
  width: 32px;
  height: 32px;
  .icon {
    font-size: 1rem;
  }
}

.md {
  width: 40px;
  height: 40px;
  .icon {
    font-size: 1.5rem;
  }
}

.lg {
  width: 48px;
  height: 48px;
  .icon {
    font-size: 2rem;
  }
}

.xl {
  width: 64px;
  height: 64px;
  .icon {
    font-size: 2.5rem;
  }
}

.square {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

.primary.plain,
.primary.plain:focus {
  color: $fwd-orange;
  background-color: transparent;
  box-shadow: none;
  svg path {
    fill: $fwd-orange;
  }
}

.secondary.plain,
.secondary.plain:focus {
  color: $font-black;
  svg path {
    fill: $font-black;
  }
}

.primary.plain:hover,
.secondary.plain:hover {
  background-color: rgba(red($fwd-orange), green($fwd-orange), blue($fwd-orange), 0.2);
}

.darkMode,
.darkMode:focus {
  background-color: white;
  color: $fwd-orange;
  box-shadow: none;
  svg path {
    fill: $fwd-orange;
  }
}

.secondary.darkMode {
  box-shadow: $btn-box-shadow;
}

.darkMode:hover {
  color: white;
  background-color: $fwd-orange;
  box-shadow: $btn-box-shadow;
  color: $font-black;
  svg path {
    fill: white;
  }
}

.plain.darkMode,
.plain.darkMode:focus {
  color: white;
  background-color: transparent;
  box-shadow: none;
  svg path {
    fill: white;
  }
}

.plain.darkMode:hover {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
